<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <button class="p-link" id="btn_back" @click="backToRapport">
          <i class="pi pi-angle-left"></i>
        </button>
        <div class="my-2">
          <h3>{{ t('listOfRequirementReassess') }}</h3>
        </div>
      </template>
      <template v-slot:end>
        <ul class="block_list">
          <li class="download_1">
            <Button icon="pi pi-download" class="button-table mr-2" mode="basic" @click="exportDocuments"
                    label="Import" chooseLabel="Import"/>
          </li>
        </ul>
      </template>
    </Toolbar>
    <div class="col-12">
      <div class="card">
        <DataTable :value="evaluations"  :totalRecords="totalItemsEvaluation"
                   dataKey="id" :paginator="true" :rows="30"
                   :rowHover="true"
                   class="p-datatable-gridlines table-filter-texte"
                   v-model:filters="filters1" filterDisplay="menu" :loading="loadingEvaluation" :filters="filters1"
                   responsiveLayout="scroll"
                   :globalFilterFields="['title','start_date','end_date','revised_end_date','user_pilot','user_in_charge','rate_progress','priority']"
                   @filter="filterDataTable($event)">
          <template #loading>
            Loading data. Please wait.
          </template>
<!--          <template #header>-->
<!--            <div class="table-header-container">-->
<!--              <div class="grid formgrid">-->

<!--                <div class="field-control col">-->
<!--                  <label for="theme">{{ t('status') }}</label>-->
<!--                  <Dropdown v-model="statusValue" :options="listStatus" optionLabel="name"-->
<!--                            :placeholder="t('select')"/>-->
<!--                </div>-->
<!--                <div class="field-control py-4 btn-search">-->
<!--                  <Button type="button" class="mr-2 mb-2 btn-search" :label="t('search')" icon="pi pi-search"-->
<!--                          :loading="loading" @click="getEvaluationsReport()"/>-->
<!--                </div>-->
<!--                <div class="field-control col">-->

<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
<!--          </template>-->
          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>

          <Column field="name" :header="t('text')" style="width:10rem" filterField="titleDescription"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div class="tooltip"
                   v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title_document+'</p><p><strong>Description :</strong> '+
                    data.description_document+'</p>'">
                <p v-html="data.title_document"></p>
                <p v-html="data.description_document" v-if="data.description_document !== 'null'"></p>
              </div>
            </template>
            <!--            <template #filter="{filterModel}">-->
            <!--              <InputText type="text" v-model="filterModel.value" class="p-column-filter"-->
            <!--                         :placeholder="t('search')"/>-->
            <!--            </template>-->
          </Column>
          <Column field="name" :header="t('titleOfCompliance')" style="width:10rem" filterField="titleDescription"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div class="tooltip"
                   v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title_section+'</p>'">
                <p v-html="data.title_section"></p>
              </div>
            </template>
            <!--            <template #filter="{filterModel}">-->
            <!--              <InputText type="text" v-model="filterModel.value" class="p-column-filter"-->
            <!--                         :placeholder="t('search')"/>-->
            <!--            </template>-->
          </Column>
          <Column field="name" :header="t('textOfCompliance')" style="width:10rem" filterField="titleDescription"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div class="tooltip"
                   v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title_compliance+'</p>'">
                <p v-html="data.title_compliance"></p>
              </div>
            </template>
            <!--            <template #filter="{filterModel}">-->
            <!--              <InputText type="text" v-model="filterModel.value" class="p-column-filter"-->
            <!--                         :placeholder="t('search')"/>-->
            <!--            </template>-->
          </Column>
          <Column field="text" :header="t('evaluation')" style="width:10rem">
            <template #body="slotProps">
                          <span style=" vertical-align: middle" class="image-text">
                              <span>
                               {{ slotProps.data.evaluation_status }}
                                </span>
                    </span>
            </template>
          </Column>
          <Column field="text" :header="t('comment')" style="width:10rem">
            <template #body="slotProps">
                          <span style=" vertical-align: middle" class="image-text">
                              <span>
                               {{ slotProps.data.comment }}
                                </span>
                    </span>
            </template>
          </Column>
          <Column :header="t('valuationDate')" style="min-width:8rem" filterField="start_date"
                  :showFilterMatchModes="false">
            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.evaluation_date) }}</span>
            </template>
            <!--            <template #filter="{filterModel}">-->
            <!--              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>-->
            <!--            </template>-->
          </Column>
          <Column field="text" :header="t('attachedActionPlan')" style="width:10rem">
            <template #body="{data}">
              <div v-if="data.title_action_plan" class="tooltip"
                   v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title_action_plan+'</p>'">
                <p v-html="data.title_action_plan"></p>
              </div>
              <p v-else> Non</p>
            </template>
          </Column>
          <Column field="site_name" header="Site" style="min-width:7rem">
            <template #body="{data}">
              <span v-if="data.parent_site_name">
                {{ data.parent_site_name }} - {{data.site_name}}
              </span>
              <span v-else>
                {{ data.customer_name }} - {{data.site_name}}
              </span>
            </template>
          </Column>

        </DataTable>

      </div>
    </div>
  </div>
  <Toast/>
</template>

<script>
import {usePrimeVue} from "primevue/config";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {computed} from "vue";
import {useToast} from "primevue/usetoast";

export default {
  name: "Evaluation",
  data() {
    return {
      evaluations: [],
      totalItemsEvaluation: 0,
      statusValue: {id: 313, name: 'A réévaluer'},
      listStatus: [{id: null, name: ''}],
      loadingEvaluation: false,
      loading: false,
    }
  },
  setup() {
    const primeVue = usePrimeVue();
    const store = useStore();
    const id_site = computed(() => {
      return store.getters.currentSite.id
    });
    const toast = useToast();
    const user = computed(() => {
      return store.getters.currentUser
    });
    return {t: primeVue.config.StoreTranslation.t, store, id_site, toast, user}
  },
  mounted() {
    this.getEvaluationsReport();
  },
  methods: {
    backToRapport() {
      this.$router.push('/rapport');
    },
    getEvaluationsReport() {
      this.loadingEvaluation = true
      this.loading = true
      this.store.dispatch(Actions.EVALUATION_REPORT, {status: this.statusValue.id, site: this.id_site, join_AP: true, customHeader: true}).then(data => {
        this.evaluations = data
        this.loadingEvaluation = false
        this.evaluations.forEach(element=>{
          if(!this.listStatus.find(item=> item.id === element.evaluation_status_id))
            this.listStatus.push({id: element.evaluation_status_id, name: element.evaluation_status})
        })
        this.totalItemsEvaluation = data.length
        this.loading = false
      })
    },
    formatDate(value) {
      if (value) {
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      } else return ' '
    },
    exportDocuments() {
      let user = '/api/users/' + this.user.id
      this.store.dispatch(Actions.EXPORTS_POST, {
        user: user,
        model: "evaluationReportExport",
        params: {
          site_id: this.id_site,
          status: this.statusValue.id,
          // text_content: this.textAdmin,
          // is_applicable: this.is_applicable,
          // rate_progress: this.rateProgressEvalFilter,
          // rate_conformity: this.rateConformityEvalFilter,
        },
        site_id: parseInt(this.id_site),
      }).then(() => {
        this.toast.add({
          severity: 'success',
          detail: this.t('exportWasSuccessfullySent'),
          life: 8000
        });
      })
    },

  }
}
</script>

<style scoped>

</style>